import Vue from "vue";
import Router from "vue-router";

import store from "@/store";
import UnauthenticatedLayout from "@/views/layout/UnauthenticatedLayout";
import AuthenticatedLayout from "@/views/layout/AuthenticatedLayout";
import Login from "@/views/framework/Login";

const UsersManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/framework/UsersManager.vue"
  );
const GroupsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/framework/GroupsManager.vue"
  );
const ApplicationsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/framework/ApplicationsManager.vue"
  );
const NotificatorManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/framework/NotificationsManager.vue"
  );
const ServicesManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/services/ServicesManager.vue"
  );
const AccountingManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/accounting/AccountingManager.vue"
  );
const BundlesManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/services/BundlesManager.vue"
  );

const RegistryManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/services/Registry.vue"
  );

const PricesListsManager = () =>
  import(/* webpackChunkName: "admin-bundle" */ "@/views/prices/PriceList.vue");

const PricesListsFilesManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/prices/PriceListsFilesManager.vue"
  );

const PricesListsPromotions = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/prices/PriceListsPromotions.vue"
  );

const LabTemplatesFiles = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/lab/TemplatesFileManager.vue"
  );
const Logs = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Logs.vue");

const DashboardLab = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/dashboards/LabDashboard.vue"
  );
const DashboardClinic = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/dashboards/ClinicDashboard.vue"
  );
const SalesDashboard = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/dashboards/SalesDashboard.vue"
  );
const DashboardAccountant = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/dashboards/AccountantDashboard.vue"
  );
const Recovery = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/framework/Recovery.vue"
  );

const Reset = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Reset.vue");
const Profile = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Profile.vue");

const Identity = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/framework/Identity.vue"
  );

const AvailabilityManager = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/clinic/AvailabilityManager.vue"
  );

const FileManager = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/framework/FileManager.vue"
  );

const CrmCategoriesManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/crm/CategoriesManager.vue"
  );

const CrmDocumentsManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/crm/DocumentsManager.vue"
  );

const CrmVideoManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/crm/VideoManager.vue"
  );

const CrmNewsManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/crm/NewsManager.vue"
  );

const StructuresManager = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/services/StructuresManager.vue"
  );

const AgentsManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/crm/AgentsManager.vue"
  );

const InvoicesManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/accounting/InvoicesManager.vue"
  );

const InvoicesReportsManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/accounting/InvoicesReportsManager.vue"
  );
const InvoicesExpiredManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/accounting/InvoicesExpiredManager.vue"
  );

const DeltasManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/accounting/DeltasManager.vue"
  );

const DeltasReportsManager = () =>
  import(
    /* webpackChunkName: "financial-bundle" */ "@/views/accounting/DeltasReportsManager.vue"
  );

const LabMolecularCheckIn = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/checkin/LabMolecularCheckIn.vue"
  );

const LabBiochemicalCheckIn = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/checkin/LabBiochemicalCheckIn.vue"
  );

const FamiliesManager = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/FamiliesManager.vue"
  );

const ClinicCheckIn = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/checkin/ClinicCheckin.vue"
  );

const CheckInManager = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/checkin/CheckinManager.vue"
  );
const PreCheckInManager = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/checkin/PreCheckInManager.vue"
  );
const LabSamples = () =>
  import(/* webpackChunkName: "lab-bundle" */ "@/views/lab/LabSamples.vue");
const LabBiochemicalSamples = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabBiochemicalSamples.vue"
  );
const LabGeneticProfiles = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabGeneticProfiles.vue"
  );
const LabGeneticProfilesCompare = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabGeneticProfilesCompare.vue"
  );
const LabGeneticProfilesManage = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabGeneticComparesManage.vue"
  );
const LabSNPProfiles = () =>
  import(/* webpackChunkName: "lab-bundle" */ "@/views/lab/LabSNPProfiles.vue");

const LabSNPProfilesManage = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabSNPProfilesManage.vue"
  );
const LabSNPTables = () =>
  import(/* webpackChunkName: "lab-bundle" */ "@/views/lab/LabSNPTables.vue");
const LabSNPManageTables = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabSNPManageTables.vue"
  );

const LabElegibility = () =>
  import(/* webpackChunkName: "lab-bundle" */ "@/views/lab/LabElegibility.vue");
const LabElegibilityNotifications = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabElegibilityNotifications.vue"
  );
const LabMolecular = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabMolecularFlow.vue"
  );
const LabBiochemical = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabBiochemicalFlow.vue"
  );
const LabExternal = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabExternalFlow.vue"
  );
const LabCollections = () =>
  import(/* webpackChunkName: "lab-bundle" */ "@/views/lab/LabCollections.vue");
const LabReports = () =>
  import(/* webpackChunkName: "lab-bundle" */ "@/views/lab/LabReports.vue");
const LabDownloadSamples = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabDownloadSamples.vue"
  );
const LabArchiveSamples = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/LabArchiveSamples.vue"
  );
const LabTemplates = () =>
  import(/* webpackChunkName: "lab-bundle" */ "@/views/lab/LabTemplates.vue");

const AnalisysLabCategoriesManager = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/AnalisysCategoriesManager.vue"
  );

const SamplesLabCategoriesManager = () =>
  import(
    /* webpackChunkName: "lab-bundle" */ "@/views/lab/SamplesCategoriesManager.vue"
  );

const LogisticShipments = () =>
  import(
    /* webpackChunkName: "logistic-bundle" */ "@/views/logistic/Shipments.vue"
  );

const LogisticKits = () =>
  import(/* webpackChunkName: "logistic-bundle" */ "@/views/logistic/Kits.vue");

const ClinicRoomManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/clinic/ClinicRoomManager.vue"
  );
const ClinicAvailabilityServicesManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/clinic/ClinicAvailabilityServicesManager.vue"
  );
const ClinicAvailabilityOrganizerManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/clinic/ClinicAvailabilityOrganizerManager.vue"
  );
const ClinicAssociateRoomServicesManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/clinic/ClinicAssociateRoomServicesManager.vue"
  );
const ClinicAssociateServiceEquipmentManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/clinic/ClinicAssociateServiceEquipmentsManager.vue"
  );
const ClinicAppointmentManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/clinic/ClinicAppointmentManager.vue"
  );

const ClinicMedicalEquipmentManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/clinic/ClinicMedicalEquipmentManager.vue"
  );

const ProformaManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/accounting/ProformaManager.vue"
  );
const ProformaReport = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/accounting/ProformaReport.vue"
  );
const ProformaExceptions = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/accounting/ProformaExceptions.vue"
  );

const SalesCalendar = () =>
  import(
    /* webpackChunkName: "sales-bundle" */ "@/views/crm/SalesCalendar.vue"
  );

const SalesCustomers = () =>
  import(
    /* webpackChunkName: "sales-bundle" */ "@/views/crm/SalesCustomers.vue"
  );
const SalesProforma = () =>
  import(
    /* webpackChunkName: "sales-bundle" */ "@/views/crm/SalesProforma.vue"
  );
const SalesSurveys = () =>
  import(/* webpackChunkName: "sales-bundle" */ "@/views/crm/SalesSurveys.vue");

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/auth",
      component: UnauthenticatedLayout,
      children: [
        {
          path: "/login",
          name: "Login",
          component: Login,
        },
        {
          path: "/recovery",
          name: "Recovery",
          component: Recovery,
        },
        {
          path: "/reset",
          name: "Reset",
          component: Reset,
        },
      ],
    },

    {
      path: "/gbl",
      component: AuthenticatedLayout,
      children: [
        {
          path: "/dashboard-lab",
          name: "dashboard-lab",
          component: DashboardLab,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/dashboard-clinic",
          name: "dashboard-clinic",
          component: DashboardClinic,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/dashboard-accountant",
          name: "dashboard-accountant",
          component: DashboardAccountant,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/dashboard-sales",
          name: "dashboard-sales",
          component: SalesDashboard,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/dashboard-calendar",
          name: "dashboard-calendar",
          component: ClinicAppointmentManager,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/invoices-reports",
          name: "invoices-reports",
          component: InvoicesReportsManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/invoices-expired",
          name: "invoices-expired",
          component: InvoicesExpiredManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/invoices",
          name: "invoices",
          component: InvoicesManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/deltas-reports",
          name: "deltas-reports",
          component: DeltasReportsManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },

        {
          path: "/deltas",
          name: "deltas",
          component: DeltasManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/logistic-shipments",
          name: "logistic-shipments",
          component: LogisticShipments,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/logistic-kits",
          name: "logistic-kits",
          component: LogisticKits,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/lab-molecular-checkin",
          name: "lab-molecular-checkin",
          component: LabMolecularCheckIn,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-biochemical-checkin",
          name: "lab-biochemical-checkin",
          component: LabBiochemicalCheckIn,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-families",
          name: "lab-families",
          component: FamiliesManager,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/clinic-checkin",
          name: "clinic-checkin",
          component: ClinicCheckIn,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/checkin-manager",
          name: "checkin-manager",
          component: CheckInManager,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/precheckin-manager",
          name: "precheckin-manager",
          component: PreCheckInManager,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-samples",
          name: "lab-samples",
          component: LabSamples,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-biochemical-samples",
          name: "lab-biochemical-samples",
          component: LabBiochemicalSamples,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-elegibility",
          name: "lab-elegibility",
          component: LabElegibility,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/lab-molecular",
          name: "lab-molecular",
          component: LabMolecular,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-biochemical",
          name: "lab-biochemical",
          component: LabBiochemical,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-external",
          name: "lab-external",
          component: LabExternal,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-genetic-profiles",
          name: "lab-genetic-profiles",
          component: LabGeneticProfiles,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-genetic-profiles-compare",
          name: "lab-genetic-profiles-compare",
          component: LabGeneticProfilesCompare,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-snp-tables",
          name: "lab-snp-tables",
          component: LabSNPTables,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-snp-manage-tables",
          name: "lab-snp-manage-tables",
          component: LabSNPManageTables,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/lab-genetic-profiles-manage",
          name: "lab-genetic-profiles-manage",
          component: LabGeneticProfilesManage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-snp-profiles",
          name: "lab-snp-profiles",
          component: LabSNPProfiles,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/lab-snp-profiles-manage",
          name: "lab-snp-profiles-manage",
          component: LabSNPProfilesManage,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/lab-reports",
          name: "lab-reports",
          component: LabReports,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-collections",
          name: "lab-collections",
          component: LabCollections,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-elegibility-notifications",
          name: "lab-elegibility-notifications",
          component: LabElegibilityNotifications,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/lab-download-samples",
          name: "lab-download-samples",
          component: LabDownloadSamples,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-archive-samples",
          name: "lab-archive-samples",
          component: LabArchiveSamples,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/lab-templates",
          name: "lab-templates",
          component: LabTemplates,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/lab-templates-files",
          name: "lab-templates-files",
          component: LabTemplatesFiles,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/lab-analisys-categories",
          name: "lab-analisys-categories",
          component: AnalisysLabCategoriesManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },

        {
          path: "/lab-samples-categories",
          name: "lab-samples-categories",
          component: SamplesLabCategoriesManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/permissions-users",
          name: "users",
          component: UsersManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/permissions-groups",
          name: "groups",
          component: GroupsManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/bundles",
          name: "bundles",
          component: BundlesManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/permissions-applications",
          name: "applications",
          component: ApplicationsManager,
          meta: {
            requiresAuth: true,
            requiresSuperAdmin: true,
            disableLocation: true,
          },
        },

        {
          path: "/notifications",
          name: "notifications",
          component: NotificatorManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },

        {
          path: "/registry",
          name: "registry",
          component: RegistryManager,
          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/pricelists",
          name: "pricelists",
          component: PricesListsManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/pricelists-files",
          name: "pricelists-files",
          component: PricesListsFilesManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/pricelists-promotions",
          name: "pricelists-promotions",
          component: PricesListsPromotions,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/crm-categories",
          name: "crm-categories",
          component: CrmCategoriesManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/crm-documents",
          name: "crm-documents",
          component: CrmDocumentsManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/crm-video",
          name: "crm-video",
          component: CrmVideoManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/crm-news",
          name: "crm-news",
          component: CrmNewsManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/structures",
          name: "structures",
          component: StructuresManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/accounting",
          name: "accounting",
          component: AccountingManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },

        {
          path: "/agents",
          name: "agents",
          component: AgentsManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/services",
          name: "services",
          component: ServicesManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/profile",
          name: "profile",
          component: Profile,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/identity",
          name: "identity",
          component: Identity,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/personal-agenda-availability",
          name: "personal-agenda-availability",
          component: AvailabilityManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },

        {
          path: "/filemanager",
          name: "filemanager",
          component: FileManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },

        {
          path: "/logs",
          name: "logs",
          component: Logs,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/clinic-availability-services",
          name: "clinic-availability-services",
          component: ClinicAvailabilityServicesManager,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/clinic-availability-organizer",
          name: "clinic-availability-organizer",
          component: ClinicAvailabilityOrganizerManager,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/clinic-rooms",
          name: "clinic-rooms",
          component: ClinicRoomManager,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/clinic-medical-equipment",
          name: "clinic-medical-equipment",
          component: ClinicMedicalEquipmentManager,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/clinic-room-services",
          name: "clinic-room-services",
          component: ClinicAssociateRoomServicesManager,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/clinic-service-equipment",
          name: "clinic-service-equipment",
          component: ClinicAssociateServiceEquipmentManager,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/sales-calendar",
          name: "sales-calendar",
          component: SalesCalendar,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/sales-customers",
          name: "sales-customers",
          component: SalesCustomers,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/sales-surveys",
          name: "sales-surveys",
          component: SalesSurveys,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/sales-proforma",
          name: "sales-proforma",
          component: SalesProforma,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/proforma-builder",
          name: "proforma-builder",
          component: ProformaManager,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/proforma-exceptions",
          name: "proforma-exceptions",
          component: ProformaExceptions,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
        {
          path: "/proforma-reports",
          name: "proforma-reports",
          component: ProformaReport,

          meta: {
            requiresAuth: true,
            disableLocation: true,
          },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    next("/login");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.meta.requiresAdmin && !store.state.isAdmin()) {
      next("/login");
      return;
    }

    if (to.meta.requiresSuperAdmin && !store.state.isSuperAdmin()) {
      next("/login");
      return;
    }

    if (store.state.user.id > 0) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
