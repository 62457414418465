<template>
  <div>
    <v-data-table :items-per-page="_options.itemsPerPage" :must-sort="mustSort" :headers="headers" :items="items"
      :options.sync="_options" :server-items-length="totalItems" :loading="loading" :group-by="groupBy"
      :group-desc="groupDesc" :item-class="rowClass" v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners"
      :class="getClass">
      <template v-slot:progress>
        <v-progress-linear height="5" rounded indeterminate striped color="#8598ca"></v-progress-linear>
      </template>

      <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>

      <template v-slot:top>
        <v-row dense class="ma-2">
          <slot name="preSearchFilterBar"></slot>
          <v-col v-if="search" :cols="searchColumns">
            <FormTextInput :label="searchLabel" outlined v-model="searchStaticModel" :value="_searchModel"
              @keydown.enter="handleSearch()" @blur="handleSearch()" :placeholder="$t('gbl-search')"
              :disabled="loading && disableFilterOnLoading">
              <template slot="prepend-inner">
                <v-icon style="top: -5px" color="#adb5bd" size="1rem">fas fa-search</v-icon>
              </template>
            </FormTextInput>
          </v-col>
          <slot name="filterBar"></slot>
        </v-row>
        <v-row dense v-if="filterBarSecondRow" class="ma-2">
          <slot name="filterBarSecondRow"></slot>
        </v-row>
        <v-row dense v-if="filterBarThirdRow" class="ma-2">
          <slot name="filterBarThirdRow"></slot>
        </v-row>
      </template>

      <template v-for="(index, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>

      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </v-data-table>

    <div class="card-padding" v-if="pagination">
      <v-row> </v-row>
      <v-row>
        <v-col cols="4" class="d-flex align-center">
          <label class="text-sm text-typo font-weight-bold ms-1 mr-2">
            {{ $t("$vuetify.dataFooter.itemsPerPageText") }}</label>

          <v-select :items="itemsPerPageOptions" v-model="_options.itemsPerPage" color="rgba(0,0,0,.6)"
            class="input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 mb-0"
            outlined hide-details single-line height="38" style="max-width: 100px">
          </v-select>
        </v-col>
        <v-col cols="4" class="d-flex justify-center"> </v-col>

        <v-col cols="4" class="ml-auto d-flex align-center justify-end">
          <v-pagination prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right" class="pagination" color="#8598ca"
            v-model="_page" :length="_pageCount"></v-pagination>
          <label class="text-sm text-typo font-weight-bold ms-1 mr-2">
            {{ totalItems }} {{ $t("$vuetify.dataFooter.totalItems") }}
          </label>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import staticData from "@/apis/static";

export default {
  data() {
    return {
      searchStaticModel: null,
    };
  },

  watch: {
    searchModel: {
      handler() {
        this.searchStaticModel = this._searchModel;
      },
      immediate: true,
    },
  },

  computed: {
    commonAttrs() {
      return {
        class: "text-xxs font-weight-normal text-body table",
        hideDefaultFooter: true,
        mobileBreakpoint: "0",
      };
    },

    getClass() {
      if (this.blueHover) {
        return "universal-selectable-table universal-table elevation-1 ml-1 mr-1";
      }
      return " universal-table elevation-1 ml-1 mr-1";
    },

    _options: {
      get() {
        return this.options;
      },
      set(value) {
        this.$emit("update:options", value);
      },
    },
    _searchModel: {
      get() {
        return this.searchModel;
      },
      set(value) {
        this.$emit("update:searchModel", value);
      },
    },
    _page: {
      get() {
        return this.page;
      },
      set(value) {
        this.$emit("update:page", value);
      },
    },

    _pageCount: {
      get() {
        if (!this.totalItems || !this.options.itemsPerPage) return 1;
        return Math.ceil(this.totalItems / this.options.itemsPerPage);
      },
    },
  },

  emits: ["update:options", "update:searchModel", "update:page"],

  props: {
    pagination: {
      type: Boolean,
      required: false,
      default: false,
    },

    page: {
      type: Number,
      required: false,
      default: 1,
    },

    itemsPerPageOptions: {
      type: Array,
      required: false,
      default: () => {
        return staticData.dataTableFooterOptions["items-per-page-options"];
      },
    },

    search: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableFilterOnLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    filterBarSecondRow: {
      type: Boolean,
      required: false,
      default: false,
    },

    filterBarThirdRow: {
      type: Boolean,
      required: false,
      default: false,
    },

    searchColumns: {
      type: Number,
      required: false,
      default: 6,
    },

    searchModel: {
      type: String,
      required: false,
      default: null,
    },

    searchLabel: {
      type: String,
      required: false,
      default: null,
    },

    mustSort: {
      type: Boolean,
      required: false,
      default: true,
    },

    groupBy: {
      type: String,
      required: false,
      default: null,
    },

    groupDesc: {
      type: Boolean,
      required: false,
      default: true,
    },

    headers: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    blueHover: {
      type: Boolean,
      required: false,
      default: false,
    },

    options: {
      type: Object,
      required: false,
      default: () => {
        return {
          multiSort: false,
          mustSort: false,
          sortBy: ["id"],
          sortDesc: [false],
          itemsPerPage: 50,
          page: 1,
          filter: null,
        };
      },
    },
    totalItems: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mounted() {
    this.searchStaticModel = this._searchModel;
  },

  methods: {
    handleSearch() {
      this._searchModel = this.searchStaticModel;
    },

    rowClass(item) {
      let itemClass = "universal-table-item";
      if (item.highlight_gray) itemClass += " universal-table-item-gray";
      if (item.highlight) itemClass += " universal-table-item-highlight";
      if (item.highlight_red) itemClass += " universal-table-item-red";
      if (item.highlight_yellow) itemClass += " universal-table-item-yellow";
      return itemClass;
    },
  },
};
</script>

<style>
.universal-table>>>tr:hover {
  background: transparent !important;
  background-color: transparent !important;
}

.universal-table .universal-table-item-red:hover {
  background-color: #af2a6b !important;
}

.universal-table .universal-table-item-yellow:hover {
  background-color: #e4c44f !important;
}

.universal-table .universal-table-item-gray {
  background-color: #f1f1f1f5 !important;
}

.universal-table .universal-table-item-highlight {
  background-color: #afb9d0 !important;
}

.universal-table .universal-table-item-red {
  background-color: #af2a6b !important;
  color: white;
}

.universal-table .universal-table-item-yellow {
  background-color: #e4c44f !important;
  color: #4e4e4e;
}

.universal-selectable-table tbody tr:hover td {
  background: #8997c6;
  color: white;
}

/*.universal-table .universal-table-item-red {
  background-image: linear-gradient(
    #af2a6b,
    white,
    white,
    white,
    white,
    white,
    #af2a6b
  ) !important;
}

.universal-table .universal-table-item-yellow {
  background-image: linear-gradient(
    #e4c44f,
    white,
    white,
    white,
    white,
    white,
    #e4c44f
  ) !important;
}*/
</style>
